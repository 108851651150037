/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;
  background-color: black;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.2);
  font-weight: 100;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  color: white;
  letter-spacing: 0.01px;
  
  font-family:'Val-font';

  &,
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
    text-decoration: none;
  }
}

.site-nav {
  //float: right;
  text-align: center;
  line-height: $base-line-height * $base-font-size * 2.25;
  background: black !important;
  z-index: 2 !important;
  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: white;
    line-height: $base-line-height;
    font-weight: 500;
    letter-spacing: 0.2px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 7px;
    //border: 3px solid #c44bc0;
    //border: 3px solid #59d8b1; 
    transition: all .3s ease-in;

    &:hover {
    color: #ff3d00;
    transition: all .3s ease-out;
    //color: $grey-color-light;
    //border: 3px solid #d171cd;
    //border: 3px solid #90e4c1;
    text-decoration:none;
    }

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }


  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;
      

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}
.post-list-heading {
  color: $grey-color;
  border-bottom: 2px solid $grey-color-light;
  @include relative-font-size(1.2);
  letter-spacing: 0.2px;
}
.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  font-weight: bold;
  @include relative-font-size(1.5);
  @include media-query($on-palm) {
    @include relative-font-size(1);
  }
}
.post-link-small {
  display: block;
  font-weight: bold;
  @include relative-font-size(1);
}
.post-link-interactive {
    font-size: $small-font-size;
    color: white;
    line-height: $base-line-height;
    font-weight: 500;
    //letter-spacing: 0.2px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 7px;
    margin-left: 18px;
    border: 1px solid #2b2f36;
    background-color: #2b2f36; 

    &:hover {
    color: #2b2f36;
    border: 1px solid #2b2f36;
    text-decoration: none;
    background-color: white;
    }


}
.post-link-interactive2 {
    font-size: $small-font-size;
    color: white;
    line-height: $base-line-height;
    font-weight: 500;
    //letter-spacing: 0.2px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 7px;
    margin-left: 22px;
    border: 1px solid #2b2f36;
    background-color: #2b2f36; 

    &:hover {
    color: #2b2f36;
    border: 1px solid #2b2f36;
    text-decoration: none;
    background-color: white;
    }


}
.post-link-interactive3 {
    font-size: $small-font-size;
    color: white;
    line-height: $base-line-height;
    font-weight: 500;
    //letter-spacing: 0.2px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 7px;
    border: 1px solid #2b2f36;
    background-color: #2b2f36; 

    &:hover {
    color: #2b2f36;
    border: 1px solid #2b2f36;
    text-decoration: none;
    background-color: white;
    }


}


/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}
/**
.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
  @include media-query($on-palm) {
    @include relative-font-size(1.25);
  }
}
*/
.post-title{
  @include relative-font-size(2.625);
  letter-spacing: 0px;
  font-weight: 1000;
  line-height: 1;
  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
  @include media-query($on-palm) {
    @include relative-font-size(1.25);
  }
}
.post-narrow{
  margin-left: 20%;
  margin-right: 20%;
}
.relative-post-content {
  @include relative-font-size(1.125);

  @include media-query($on-laptop) {
    @include relative-font-size(1.075);
  }
  @include media-query($on-palm) {
    @include relative-font-size(0.56);
  }
}
.post-content {
  margin-bottom: $spacing-unit;
  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
    @include media-query($on-palm) {
      @include relative-font-size(0.76);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
.post-content-narrow{
  margin-bottom: $spacing-unit;
  margin-left: 7*$spacing-unit;
  margin-right: 7*$spacing-unit;
  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
    @include media-query($on-palm) {
      @include relative-font-size(0.76);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

/**
 * Model Page
 */
.page-header {
  margin-bottom: $spacing-unit;
}

.model-title {
  @include relative-font-size(2.625);
  letter-spacing: 0px;
  font-weight: 1000;
  line-height: 1;
  text-align: center;
  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
  @include media-query($on-palm) {
    @include relative-font-size(1.25);
  }
}
.model-excerpt{
  text-align: center;
}

/**
 * Allposts Page
 */
.allpostspage-excerpt{
  text-align: left;
  color: grey;
  font-weight: normal;
}


.model-content {
  margin-bottom: $spacing-unit;
   
  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
    @include media-query($on-palm) {
      @include relative-font-size(0.76);
    }
  }

  h3 {
    @include relative-font-size(1.625);
    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
    @include media-query($on-palm) {
      @include relative-font-size(0.26);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
    @include media-query($on-palm) {
      @include relative-font-size(0.76);
    }
  }
}
.page-meta {
  font-size: $small-font-size;
  color: $grey-color;
  text-align: center;
}



//split_title_page
/* Set additional styling options for the columns */
.column {
float: left;
}

/* Set width length for the left, right and middle columns */
.left {
width: 47%;
padding-right: 15px;
}

.middle {
width: 20%;
padding-left: 15px;
padding-right: 15px;
border-right: 2px solid $grey-color-light;
border-left: 2px solid $grey-color-light;
}

.right {
width: 25%;
padding-left: 15px;
}


.bottom {
//width: 25%;
margin-top:15px;
padding-top:10px;
border-top: 2px solid $grey-color-light;
}

.post-element{
margin-top: -15px;
margin-bottom: -15px;
//border-top: 2px solid $grey-color-light;
border-bottom: 2px solid $grey-color-light;
}



//Images
.img_logo{
  max-width:70px;
  height:auto;
  position: relative;
  top: -30px;
  //padding-left: 6px;
  }
.img_logo_small{
  max-width:22px;
  height:auto;
  position: relative;
  opacity: 0;
  margin-right: 5px;
  margin-bottom: 11px;
  }
.home_image{
  width:80%; 
  height:auto;
  border: 3px solid black;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  display: block;
  }
.img_polling{
  max-width:100%;
  height:auto;
  //border: 3px solid black;
  //margin-top: 12px;
  //padding-left: 6px;
  //border: 3px solid black;
  }
.img_tip_jar{
  max-width:100%;
  height:auto;
  //border: 3px solid black;
  //margin-top: 12px;
  //padding-left: 6px;
  //border: 3px solid black;
  margin-bottom: 10px;
  }
.center-image
{
    margin: 0 auto;
    display: block;
    width:40%;
    height:auto;
    image-rendering: -webkit-optimize-contrast;

}
.center-image-full
{
    margin: 0 auto;
    display: block;
    width:100%;
    height:auto;
    image-rendering: -webkit-optimize-contrast;

}
.center-image-mid
{
    margin: 0 auto;
    display: block;
    width:60%;
    height:auto;
    image-rendering: -webkit-optimize-contrast;

}

